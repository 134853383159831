import React from "react"
import ProductsLayout from "../../components/productsLayout"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import ImageGallery from "react-image-gallery"
import Img1 from "../../images/tvrebrasti3.jpg"
import Img2 from "../../images/tvrebrasti2.jpg"
import Img3 from "../../images/tvrebrasti.jpg"
import Head from "../../components/head"

const toplovaljaniRebrastiLimovi = () => {
  const images = [
    {
      original: `${Img1}`,
      thumbnail: `${Img1}`,
    },
    {
      original: `${Img2}`,
      thumbnail: `${Img2}`,
    },
    {
      original: `${Img3}`,
      thumbnail: `${Img3}`,
    },
  ]
  return (
    <Layout>
      <Head title="Toplovaljani rebrasti limovi" />
      <div className="products-layout">
        <section className="hero is-link">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <h1 className="title">Toplovaljani rebrasti limovi</h1>
                </div>
                <div className="column">
                  <nav className="breadcrumb is-right" aria-label="breadcrumbs">
                    <ul>
                      <li>
                        <Link to="/proizvodi">Proizvodi</Link>
                      </li>
                      <li class="is-active">
                        <a href="#" aria-current="page">
                          Toplovaljani rebrasti limovi
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ProductsLayout>
          <div className="columns products-list end-section">
            <div className="column">
              <ImageGallery
                additionalClass="box"
                infinite={false}
                showNav={false}
                thumbnailPosition="bottom"
                lazyLoad={true}
                items={images}
                showFullscreenButton={false}
                showPlayButton={false}
                originalClass="main-img"
              />
            </div>
            <div className="column">
              <p>
                <b>TOPLOVALJANI REBRASTI PROIZVODI</b> od konstukcionih čelika
                izradjuju se sa reljefnom površinom <b>ROMB </b>
                ili <b>SUZA</b> i upotrebljavaju se u fabričkim halama za
                konstrukcije podova, platformi, stepeništa i gazišta.
              </p>
              <br />
              <div className="columns products-list is-mobile is-multiline">
                <div className="column is-full">
                  <p>
                    <b>Hemijski sastav:</b>
                    <br />
                    Prema standardu za tehničke zahteve za isporuku JUS EN 10025
                    za toplovaljane proizvode od nelegiranih konstukcionih
                    čelika, uobičajeni kvalitet:
                  </p>
                </div>
                <div className="column">
                  <ul>
                    <li>JUS EN 10027 - 1</li>
                    <li>S 235 JRG2</li>
                  </ul>
                </div>
                <div className="column ">
                  <ul>
                    <li>DIN 17100</li>
                    <li>RSt 37-2</li>
                  </ul>
                </div>
                <div className="column ">
                  <ul>
                    <li>JUS C.B0.500</li>
                    <li>Č 0361</li>
                  </ul>
                </div>
                <div className="column is-full">
                  <b>Asortiman:</b>
                  <p>
                    Toplovaljani rebrasti limovi isporučuju se u obliku tabli:
                  </p>
                  <ul>
                    <li>debljina: 2 - 12 mm</li>
                    <li>širine: 800 - 1400 mm</li>
                    <li>dužine: do 6000 mm</li>
                    <li>masa paketa: do 4 t</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ProductsLayout>
      </div>
    </Layout>
  )
}

export default toplovaljaniRebrastiLimovi
